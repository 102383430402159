<div class="content-width">
  <div class="dashboard">
    <div class="dashboard-title">
      <div class="row">
        <div class="col">
          <span> MY DASHBOARD</span>
          <svg-icon name="import-export" svgClass="icon"></svg-icon>
          <span class="import" (click)="open('import')"> IMPORT FORECAST</span>

          <svg-icon name="download" viewBox="0 0 18 18" svgClass="icon"></svg-icon>
          <span class="download" (click)="open('download')"> DOWNLOAD TEMPLATE </span>
          <form class="k-form k-form-md" [formGroup]="form">
            <kendo-dialog *ngIf="openDialog" (close)="close()">
              <div class="d-flex flex-column border-bottom">
                <h4 class="fw-bold">{{title}}</h4>
                <p class="sub-title">{{subTitle}}
                </p>
              </div>

              <div class="mt-4" *ngIf="currentView === 'import'">

                <kendo-formfield>
                  <kendo-label text="Forecast Type"> </kendo-label>
                  <kendo-dropdownlist required="true" [data]="options" (valueChange)="valueChange($event)"
                    formControlName="forecastTypeForm" defaultItem="Select" fillMode="flat"
                    [itemDisabled]="itemDisabled"></kendo-dropdownlist>
                  <kendo-formerror>Required Field</kendo-formerror>
                </kendo-formfield>


              </div>
              <div class="dropzone mt-5" *ngIf="currentView === 'import' && !fileSelected" (drop)="onDrop($event)"
                (dragover)="onDragOver($event)">
                <div>
                  <div class="importfile">
                    <svg-icon name="upload" svgClass="uploadicon"></svg-icon>
                  </div>
                  <div>
                    <span> Drag & drop or </span> <label class="choose-file">
                      Choose file
                      <input type="file" (change)="onSelectFile($event)" accept=".csv">
                    </label>
                  </div>
                  <div>XLS or CSV template files only.</div>
                </div>
              </div>
              <div class="mt-4 d-flex" *ngIf="currentView === 'import' && fileSelected">
                <svg-icon name="file" svgClass="icon"></svg-icon>
                <span class="d-flex flex-column">
                  <span>
                    {{fileName}}
                  </span>
                  <span>{{fileSize}} KB</span>
                </span>
              </div>

              <div class="error-dropzone mt-5" *ngIf="currentView === 'import' && showErrorMessage">
                <div class="d-flex">
                  <div class="importfile">
                    <svg-icon name="file" svgClass="icon"></svg-icon>
                  </div>
                  <div class="d-flex flex-column filename">
                    <div> {{fileName}} </div>
                    <div class="error-message">Error: Forecast template not recognized. Try Again.</div>
                  </div>

                </div>
              </div>

              <ng-container *ngIf="currentView === 'download'">
                <div class="mt-4">
                  <kendo-formfield>
                    <kendo-label text="Forecast Type"> </kendo-label>
                    <kendo-dropdownlist required="true" [data]="options" (valueChange)="valueChange($event)"
                      defaultItem="Select" formControlName="downloadForecast" fillMode="flat"
                      [itemDisabled]="itemDisabled"></kendo-dropdownlist>
                    <kendo-formerror>Required Field</kendo-formerror>
                  </kendo-formfield>
                </div>
                <div class="mt-4">
                  <kendo-formfield>
                    <kendo-label text="Select Year"> </kendo-label>
                    <kendo-dropdownlist required="true" [data]="years" (valueChange)="valueChange($event)"
                      defaultItem="Select" formControlName="forecastYear" fillMode="flat"
                      [itemDisabled]="itemDisabled"></kendo-dropdownlist>
                    <kendo-formerror>Required Field</kendo-formerror>
                  </kendo-formfield>
                </div>
              </ng-container>
              <div class=" action-btn mt-3">
                <button type="button" class="btn btn-action-primary btn-above-overlay ms-2 m-3"
                  [disabled]="disableSaveBtn" (click)="onSave()">Save</button>
                <button type="button" class="btn btn-action-secondary btn-above-overlay ms-2 m-3"
                  (click)="close()">Cancel</button>

              </div>
            </kendo-dialog>
          </form>

          <kendo-dialog *ngIf="openSecondDialog" [minWidth]="250" [width]="500" (close)="closeSecondDialog()">
            <div class="d-flex flex-column border-bottom align-items-center warning-dialog">
              <svg-icon name="warning" svgClass="icon"></svg-icon>

              <h4 class="fw-bold warning">Warning!</h4>

              <p class="sub-title fw-bold">Only the data entered in this forecast import will be displayed.
                Would you like to proceed?
              </p>

              <div class=" action-btn mt-3">

                <button type="button" class="btn btn-action-secondary btn-above-overlay go-back ms-2 m-3"
                  (click)="closeSecondDialog()">GO BACK</button>
                <button type="button" class="btn btn-action-primary btn-above-overlay ms-2 m-3"
                  (click)="importFile()">IMPORT</button>


              </div>
            </div>

          </kendo-dialog>

        </div>
        <div class="col d-flex justify-content-end toggle-primary">
          <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
            <!--<input type="radio" class="btn-check" name="btnRadioFCToggle" id="btnRadioMyData"
              [disabled]="isAssociationUser" autocomplete="off" (change)="onOptionChange($event)"
              [(ngModel)]="selectedOption" value="MY_DATA">
            <label class="btn btn-outline-primary" for="btnRadioMyData">MY DATA</label>-->

            <input type="radio" class="btn-check" name="btnRadioFCToggle" id="btnRadioAssociation"
              [disabled]="!isAssociationUser" autocomplete="off" (change)="onOptionChange($event)"
              [(ngModel)]="selectedOption" value="ASSOCIATION">
            <label class="btn btn-outline-primary" for="btnRadioAssociation">ASSOCIATION</label>

            <!--<input type="radio" class="btn-check" name="btnRadioFCToggle" id="btnRadioNetworkData"
              autocomplete="off" [(ngModel)]="selectedOption" (change)="onOptionChange($event)" value="NETWORK_DATA">
            <label class="btn btn-outline-primary" for="btnRadioNetworkData">NETWORK DATA</label>-->
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-6 p-0 pe-1">
        <div class="card">
          <div class="row d-flex justify-content-between">
            <div class="col-6">
              <div class="visual_title ps-1">Volume Demand</div>
              <div class="visual_subtitle ps-1">Description</div>
            </div>
            <button type="button" class="col-auto btn btn-link text-decoration-none more-info me-1"
              routerLink="/forecast-dashboard/reports/volume-demand">MORE
              INFO &nbsp;&nbsp;<svg-icon name="arrow-right-solid" viewBox="0 0 18 18"
                svgClass="icon"></svg-icon></button>
          </div>
          <div class="row">
            <app-forecast-volume-demand-chart></app-forecast-volume-demand-chart>
          </div>
        </div>
      </div>
      <div class="col-6 p-0 pe-1">
        <div class="card">
          <div class="row d-flex justify-content-between">
            <div class="col-4">
              <div class="visual_title ps-1">Forecast Summary</div>
              <div class="visual_subtitle ps-1">Description</div>
            </div>
            <button type="button" class="col-auto btn btn-link text-decoration-none more-info me-1"
              routerLink="/forecast-dashboard/reports/forecast-demand">MORE
              INFO &nbsp;&nbsp;<svg-icon name="arrow-right-solid" viewBox="0 0 18 18"
                svgClass="icon"></svg-icon></button>
          </div>
          <div class="row">
            <div class="d-flex">
              <div class="tile-layout" *ngFor="let item of data">
                <div class="text-center fw-bold tile-layout-heading">
                  {{item.status}}

                </div>
                <div class="text-center mb-3">
                  <span class="tile-layout-status">
                    {{item.dateMonth}}
                  </span>
                </div>
                <ng-container *ngFor="let data of item.info">
                  <div class="label-container-div ">
                    <span class="align-content-center icon-area">
                      <span class="circle"></span>
                      <svg-icon [name]="data.icon" svgClass="icon"></svg-icon>
                    </span>
                    <span class=" d-flex flex-column">
                      <span class="fw-bold"> {{data.value}}</span>

                      {{data.label}}
                    </span>
                  </div>

                </ng-container>
              </div>
            </div>
            <!-- <app-forecast-volume-demand-chart></app-forecast-volume-demand-chart> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-6 p-0 pe-1">
        <div class="card">
          <div class="row d-flex justify-content-between">
            <div class="col-4">
              <div class="visual_title ps-1">First Mile/Last Mile</div>
              <div class="visual_subtitle ps-1">Fulfillment vs Accuracy</div>
            </div>
            <button type="button" class="col-auto btn btn-link text-decoration-none more-info me-1"
              routerLink="/forecast-dashboard/reports/first-mile-last-mile">MORE
              INFO &nbsp;&nbsp;<svg-icon name="arrow-right-solid" viewBox="0 0 18 18"
                svgClass="icon"></svg-icon></button>
          </div>
          <div class="row">
            <app-forecast-first-last-mile></app-forecast-first-last-mile>
          </div>
        </div>
      </div>
      <div class="card col-6 p-0 pe-1">
        <div class="">
          <div class="row d-flex justify-content-between">
            <div class="col-4">
              <div class="visual_title ps-1">Switching Performance</div>
              <div class="visual_subtitle ps-1">Description</div>
            </div>
            <button type="button" class="col-auto btn btn-link text-decoration-none more-info me-1"
              routerLink="/forecast-dashboard/reports/switching-performance">MORE
              INFO &nbsp;&nbsp;<svg-icon name="arrow-right-solid" viewBox="0 0 18 18"
                svgClass="icon"></svg-icon></button>
          </div>
          <div class="row">
            <app-forecast-switching-performance></app-forecast-switching-performance>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>