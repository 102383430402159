<div class="forecast-volume-demand-report">
  <div class="d-flex justify-content-between forecast-volume-demand-border">
    <app-forecast-demand-report-filters [reportTitle]="reportTitle"
      (sendFilterDataToParent)="handleFilterData($event)"></app-forecast-demand-report-filters>
  </div>
  <div class="row d-flex justify-content-between forecast-volume-demand-border report-border-margin">
    <div class="col-4">
      <div class="visual_title ps-1">Volume Demand</div>
      <div class="visual_subtitle ps-1">Description</div>
    </div>
    <div>
      <div class="legend">
        <span class="dot-planned"></span>
        <span>Fulfillment</span>
        &nbsp;
        &nbsp;
        <span class="dot-inaccurate"></span>
        <span class="dot-inaccurate"></span>
        <span class="dot-inaccurate"></span>
        <span class="dot-inaccurate"></span>
        <span class="dot-margin">Max Fulfillment</span>
        &nbsp;
        &nbsp;
        <span class="dot-missed"></span>
        <span class="dot-missed"></span>
        <span class="dot-missed"></span>
        <span class="dot-missed"></span>
        <span class="dot-margin">Min Fulfillment</span>
      </div>
    </div>

    <div class="w-100 d-flex justify-content-center" *ngIf="isLoading">
      <div class="spinner-border d-flex justify-content-around" role="status">
      </div>
    </div>
    <div *ngIf="!isLoading" id="volumeDemandYearLineReport" class="d-flex justify-content-around"
      #volumeDemandYearLineReport>
      @if (dataSource && !noData) {
      <fusioncharts [width]="width" [height]="height" [type]="type" [dataFormat]="dataFormat" [dataSource]="dataSource"
        [renderAt]="'volumeDemandYearLineReport'">
      </fusioncharts>
      }
      @if(noData)
      {
      <div class="parentdiv">
        <div>
          <div class="nodataheader">No Data</div>
          <div class="nodatacontent">Select a report or adjust filters to view data.</div>
        </div>
      </div>
      }
    </div>
    <div>
      <div class="legend">
        <span class="dot-planned"></span>
        <span>Fulfilled</span>
        &nbsp;
        &nbsp;
        <span class="dot-unfulfilled"></span>
        <span>Unfulfilled</span>
      </div>
      <div class="d-flex justify-content-center gap-5">
        @if (dataSource2) {
        <fusioncharts type="doughnut2d" width="240" height="320" [dataSource]="dataSource2">
        </fusioncharts>
        }
        @if (dataSource3) {
        <fusioncharts type="doughnut2d" width="240" height="320" [dataSource]="dataSource3">
        </fusioncharts>
        }
        @if (dataSource4) {
        <fusioncharts type="doughnut2d" width="240" height="320" [dataSource]="dataSource4">
        </fusioncharts>
        }
      </div>
    </div>
    <div class="h-100 mb-2 scrolltablediv">
      <div class="col-12 card-bg table-container">
        <div class="row m-2">
          <label class="col text-center fw-bolder"></label>
        </div>
        <div class="w-100 d-flex justify-content-center" *ngIf="isLoading">
          <div class="spinner-border d-flex justify-content-around" role="status">
          </div>
        </div>
        <table *ngIf="!isLoading" class="borderenabled w-100 firstmilelastmile-table">
          <tr>
            <td class="padding-3px"> <label></label></td>
            <td class="padding-3px tablerptclmn-data-width" *ngFor="let dt  of fulfillmentData">
              <label class=" fw-light-lbl">{{dt.monthName | uppercase}}</label>
            </td>
          </tr>
          <tr>
            <td class="padding-3px"> <label class="fw-light-lbl">Fulfillment</label></td>
            <td class="padding-3px tablerptclmn-data-width" *ngFor="let dt  of fulfillmentData">
              <label class="fw-normal">{{dt.fulfillment | percent: '1.2-2'}}</label>
            </td>
          </tr>
          <tr>
            <td class="padding-3px"> <label class="fw-light-lbl">Maximum</label></td>
            <td class="padding-3px tablerptclmn-data-width" *ngFor="let dt  of fulfillmentData">
              <label class="fw-normal">{{dt.maxFulfillment | percent: '1.2-2'}}</label>
            </td>
          </tr>
          <tr>
            <td class="padding-3px"> <label class="fw-light-lbl">Minimum</label></td>
            <td class="padding-3px tablerptclmn-data-width" *ngFor="let dt  of fulfillmentData">
              <label class="fw-normal">{{dt.minFulfillment | percent: '1.2-2'}}</label>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
