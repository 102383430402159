<div class="first-mile-last-mile-report">
  <div class="d-flex justify-content-between first-mile-last-mile-border">
    <app-forecast-demand-report-filters [reportTitle]="reportTitle"
      (sendFilterDataToParent)="handleFilterData($event)"></app-forecast-demand-report-filters>
  </div>
  <div class="row d-flex justify-content-between first-mile-last-mile-border report-border-margin">
    <div class="col-4">
      <div class="visual_title ps-1">Fulfillment vs Accuracy</div>
      <div class="visual_subtitle ps-1">First Mile/Last Mile</div>
    </div>
    <div>
      <div class="legend">
        <span class="dot-planned"></span>
        <span>Fulfillment</span>
        &nbsp;
        &nbsp;
        <span class="dot-inaccurate"></span>
        <span class="dot-inaccurate"></span>
        <span class="dot-inaccurate"></span>
        <span class="dot-inaccurate"></span>
        <span>Min Fulfillment</span>
        &nbsp;
        &nbsp;
        <span class="dot-missed"></span>
        <span>Accuracy</span>
        &nbsp;
        &nbsp;
        <span class="dot-missed-dashed"></span>
        <span class="dot-missed-dashed"></span>
        <span class="dot-missed-dashed"></span>
        <span class="dot-missed-dashed"></span>
        <span>Min Accuracy</span>
      </div>
    </div>
    <div class="w-100 d-flex justify-content-center" *ngIf="isLoading">
      <div class="spinner-border d-flex justify-content-around" role="status">
      </div>
    </div>
    <div class="d-flex justify-content-around" id="firstmileYearLineReport" #firstmileYearLineReport>
      @if (dataSource && !noData) {
      <fusioncharts [width]="width" [height]="height" [type]="type" [dataFormat]="dataFormat" [dataSource]="dataSource"
                    [renderAt]="'firstmileYearLineReport'">
      </fusioncharts>
      }
      @if(noData && !isLoading)
      {
      <div class="parentdiv">
        <div>
          <div class="nodataheader">No Data</div>
          <div class="nodatacontent">Select a report or adjust filters to view data.</div>
        </div>
      </div>
      }
    </div>
    <div class="h-100 mb-2 scrolltablediv">
      <div class="col-12 card-bg table-container">
        <div class="w-100 d-flex justify-content-center" *ngIf="isLoading">
          <div class="spinner-border d-flex justify-content-around" role="status">
          </div>
        </div>
        <table *ngIf="!isLoading" class="borderenabled w-100 firstmilelastmile-table">
          <tr>
            <td class="padding-3px"> <label></label></td>
            <td class="padding-3px tablerptclmn-data-width" *ngFor="let dt  of fulfillmentData">
              <label class=" fw-light-lbl">{{dt.monthName | uppercase}}</label>
            </td>
          </tr>
          <tr>
            <td class="padding-3px"> <label class="fw-light-lbl">Fulfillment</label></td>
            <td class="padding-3px tablerptclmn-data-width" *ngFor="let dt  of fulfillmentData">
              <label class="fw-normal">{{dt.fulfillment | percent: '1.2-2'}}</label>
            </td>
          </tr>
          <tr>
            <td class="padding-12px"> <label class="fw-light-lbl">Maximum</label></td>
            <td class="padding-3px tablerptclmn-data-width" *ngFor="let dt  of fulfillmentData">
              <label class="fw-normal">{{dt.maxFulfillment | percent: '1.2-2'}}</label>
            </td>
          </tr>
          <tr>
            <td class="padding-12px"> <label class="fw-light-lbl">Minimum</label></td>
            <td class="padding-3px tablerptclmn-data-width" *ngFor="let dt  of fulfillmentData">
              <label class="fw-normal">{{dt.minFulfillment | percent: '1.2-2'}}</label>
            </td>
          </tr>
          <tr>
            <td class="padding-3px"> <label class="fw-light-lbl">Accuracy</label></td>
            <td class="padding-3px tablerptclmn-data-width" *ngFor="let dt  of fulfillmentData">
              <label class="fw-normal">{{dt.accuracy | percent: '1.2-2'}}</label>
            </td>
          </tr>
          <tr>
            <td class="padding-12px"> <label class="fw-light-lbl">Maximum</label></td>
            <td class="padding-3px tablerptclmn-data-width" *ngFor="let dt  of fulfillmentData">
              <label class="fw-normal">{{dt.maxAccuracy | percent: '1.2-2'}}</label>
            </td>
          </tr>
          <tr>
            <td class="padding-12px"> <label class="fw-light-lbl">Minimum</label></td>
            <td class="padding-3px tablerptclmn-data-width" *ngFor="let dt  of fulfillmentData">
              <label class="fw-normal">{{dt.minAccuracy | percent: '1.2-2'}}</label>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
