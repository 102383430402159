import { PercentPipe } from '@angular/common';
import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, HostListener } from '@angular/core';
import { KpiDataService } from '../../../../landing/services/kpi-data.service';
import { SubSink } from 'subsink';
import { cloneDeep } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { FirstMileLastMileAccurate } from '../../../../../models/first-mile-last-mile-report';

@Component({
  selector: 'app-forecast-first-mile-last-mile-report',
  templateUrl: './forecast-first-mile-last-mile-report.component.html',
  styleUrl: './forecast-first-mile-last-mile-report.component.scss',
  providers: [PercentPipe]
})
export class ForecastFirstMileLastMileReportComponent implements OnInit {

  reportTitle = "Accuracy Fulfillment";
  width = "85%";
  height = "280";
  type = "msline";
  dataFormat = "json";
  dataSource: any;
  isLoading = false;
  fulfillmentData: FirstMileLastMileAccurate[] = [];
  private sub = new SubSink();
  isFirstMileLastMileReportByLoading$ = new BehaviorSubject<boolean>(true);
  noData: boolean = true;

  @ViewChild('firstmileYearLineReport') childelement: ElementRef | undefined;
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.chartWidth();
  }

  ngAfterViewInit() {
    this.chartWidth();
  }

  private chartWidth() {
    const child = this.childelement?.nativeElement;
    if (child) {
      this.width = (Number(child.offsetWidth) - 100).toString();
    }
  }

  ngOnInit(): void {
    this.isLoading = true;
  }

  handleFilterData(filters: any) {
    this.sub.sink = this.kpiDataService.GetFirstMileLastMileYearReport(JSON.parse(filters)).subscribe((dwellKpi: any[]) => {
      dwellKpi.sort((a, b) => {
        if (a.year !== b.year) {
          return a.year - b.year;
        }
        return a.month - b.month;
      });
      this.isLoading = true;
      this.loadFirstMileLastMileYearReport(dwellKpi);
      this.isFirstMileLastMileReportByLoading$.next(false);
      this.isLoading = false;
    },
      (error) => {
        console.error('API call failed:', error);        
        this.isLoading = false;
        this.isFirstMileLastMileReportByLoading$.next(false);       
    });
  }

  constructor(private kpiDataService: KpiDataService,
    private percentPipe: PercentPipe
  ) {
  }

  loadFirstMileLastMileYearReport(dataset: FirstMileLastMileAccurate[]) {
    if (dataset == null) return;
    this.fulfillmentData = dataset;
    this.setFirstMileLastMileTableReport(dataset);
  }

  setFirstMileLastMileTableReport(dataset: FirstMileLastMileAccurate[]) {
    var monthlyData = cloneDeep(this.dataSource);
    this.mapReportData(dataset);
  }

  mapReportData(dataset: FirstMileLastMileAccurate[]) {
    var reportDataSource = cloneDeep(this.getFirstMileLastMileReportLineChart());
    let categories = [{ category: dataset.map(item => ({ label: item.monthName })) }];

    let fulfillmentList = dataset.map(item => ({ value: this.percentPipe.transform(item.fulfillment, '1.2-2') }));
    let minFulfillmentList = dataset.map(item => ({ value: this.percentPipe.transform(item.minFulfillment, '1.2-2') }));
    let accuracyList = dataset.map(item => ({ value: this.percentPipe.transform(item.accuracy, '1.2-2') }));
    let minAccuracyList = dataset.map(item => ({ value: this.percentPipe.transform(item.minAccuracy, '1.2-2') }));

    (reportDataSource.categories as { category: { label: string }[] }[]) = categories;

    reportDataSource.dataset.forEach((line: any) => {
      if (line.seriesname == "Fulfillment") {
        line.data = fulfillmentList;
      }
      if (line.seriesname == "Min Fulfillment") {
        line.data = minFulfillmentList;
      }
      if (line.seriesname == "Accuracy") {
        line.data = accuracyList;
      }
      if (line.seriesname == "Min Accuracy") {
        line.data = minAccuracyList;
      }
    });
    dataset.forEach((line: any) => {
      if (line.fulfillment > 0 || line.minFulfillment > 0 || line.accuracy > 0 || line.minAccuracy > 0) {
        this.noData = false;
      }
    });
    this.dataSource = reportDataSource;
  }

  getFirstMileLastMileReportLineChart() {

    let lineChartData = {
      chart: {
        caption: "",
        yaxisname: "",
        subcaption: "",
        showhovereffect: "1",
        showlegend: "0",
        numbersuffix: "%",
        drawcrossline: "1",
        drawAnchors: "0",
        labelDisplay: "rotate",
        plottooltext: "<b>$dataValue</b> of $seriesName",
        theme: "fusion"
      },
      categories: [],
      dataset: [
        {
          seriesname: "Fulfillment",
          color: "#1B365D",
          data: []
        },
        {
          seriesname: "Min Fulfillment",
          color: "#1B365D",
          dashed: "1",
          data: []
        },
        {
          seriesname: "Accuracy",
          color: "#F24444",
          data: []
        },
        {
          seriesname: "Min Accuracy",
          color: "#F24444",
          dashed: "1",
          data: []
        }
      ]
    }
    return lineChartData;
  }
}

