<div>
  <div class="content-width action-row filtermenu-width d-flex flex-row" #reportActions>
    <div class="heading py-2 ms-1">
      {{reportTitle}}
    </div>
    <div class="py-2 width300 d-flex justify-content-center">
      <kendo-dropdownlist [data]="options"
                          [itemDisabled]="reportNameDisabled"
                          textField="name"
                          valueField="id"
                          [(ngModel)]="selectedReport"
                          (valueChange)="valueChange($event)"></kendo-dropdownlist>
    </div>
    <div class="py-2">
      <button class="report-header-action" *ngIf="!opened" (click)="open()">
        <svg-icon name="checklist-no-border" viewBox="0 0 18 10" svgClass="icon"></svg-icon>
        Manage Reports
      </button>
      <kendo-dialog *ngIf="opened" (close)="close()" [minWidth]="1000" [width]="1060">
        <div class="manage-report-grid">

          <div class="d-flex flex-column margin-bottom">
            <div class="dailog-title">MANAGE REPORTS</div>
          </div>
          <div>
            <table class="table table-bordered" *ngIf="showHideManageReportData">
              <thead>
                <tr>
                  <th class="actiontdalign">
                    <div class="filtercontainer">
                      <div> Actions</div>
                    </div>
                  </th>
                  <th>
                    <div class="filtercontainer">
                      <div> Comments</div>
                      <button class="report-header-action" style="float:right">
                        <svg-icon name="filter" viewBox="0 0 18 12" svgClass="tblfiltericon"></svg-icon>
                      </button>
                    </div>
                  </th>
                  <th>
                    <div class="filtercontainer">
                      <div> Created On</div>
                      <button class="report-header-action" style="float:right">
                        <svg-icon name="filter" viewBox="0 0 18 12" svgClass="tblfiltericon"></svg-icon>
                      </button>
                    </div>
                  </th>
                  <th>
                    <div class="filtercontainer">
                      <div> Created By</div>
                      <button class="report-header-action" style="float:right">
                        <svg-icon name="filter" viewBox="0 0 18 12" svgClass="tblfiltericon"></svg-icon>
                      </button>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of filteredReportData">
                  <td>
                    <button #editPopup class="ellipsis-button"
                            (click)="toggleReportListEditMenu(item)">
                      &nbsp;
                    </button>
                    <kendo-popup [anchor]="editPopup" (anchorViewportLeave)="item.show = false"
                                 *ngIf="item.show">
                      <button class="popup-button" (click)="editReportRecord(item)">
                        <svg width="18" height="18" fill="#287DA1" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 512 512">
                          <path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" />
                        </svg>
                        Edit
                      </button>
                      <button class="popup-button" (click)="openDeleteConformaionDialog(item)">
                        <svg width="18" height="18" fill="#287DA1" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 448 512">
                          <path d="M135.2 17.7L128 32 32 32C14.3 32 0 46.3 0 64S14.3 96 32 96l384 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-96 0-7.2-14.3C307.4 6.8 296.3 0 284.2 0L163.8 0c-12.1 0-23.2 6.8-28.6 17.7zM416 128L32 128 53.2 467c1.6 25.3 22.6 45 47.9 45l245.8 0c25.3 0 46.3-19.7 47.9-45L416 128z" />
                        </svg>
                        Delete
                      </button>
                      <button class="popup-button" (click)="exportSelectedReportData(item)">
                        <svg-icon name="export" viewBox="0 0 18 18" svgClass="icon"></svg-icon>
                        Export
                      </button>
                    </kendo-popup>
                  </td>
                  <td>
                    <div *ngIf="!item.editRecord">
                      {{item.name}}
                    </div>
                    <div *ngIf="item.editRecord">
                      <input type="text" [(ngModel)]="item.name" />
                    </div>
                  </td>
                  <td>
                    {{item.created | date:'MM-dd-yyyy' : 'UTC'}}
                  </td>
                  <td>
                    {{item.createdBy}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="action-btn mt-3">
            <button type="button" class="btn btn-action-primary btn-above-overlay ms-2 m-3" (click)="updateReports()" [disabled]="enableManageReportSave">
              Save
            </button>
            <button type="button" class="btn btn-action-secondary btn-above-overlay ms-2 m-3" (click)="close()">
              Cancel
            </button>

          </div>
        </div>
      </kendo-dialog>
      <kendo-dialog *ngIf="showDeleteReportDailog" [minWidth]="250" [width]="550" [minHeight]="350" [height]="450"
                    (close)="closeManageReportWindow()">
        <div class="d-flex flex-column justify-content-center align-items-center h-100">
          <app-spinner-loader *ngIf="isDeleting" [showOverlay]="true"></app-spinner-loader>
          <img class="warning-icon mb-2" src="./../../../../../../assets/icons/warning.svg">
          <span class="warning-title mb-4">Remove Report</span>
          <span class="warning-text mb-4">Are you sure you want to remove this report template?</span>
          <div class="d-flex justify-content-between w-75">
            <button class="btn btn-action-secondary" (click)="cancelDeleteClick()"
                    [disabled]="isDeleting">
              Cancel
            </button>
            <button class="btn btn-action-primary" (click)="confirmDeleteClick()"
                    [disabled]="isDeleting">
              Delete
            </button>
          </div>
        </div>
      </kendo-dialog>
    </div>
    <div class="py-2">
      <button class="report-header-action" [disabled]="enableSaveReport" (click)="openReportSaveDialog()">
        <svg-icon name="save" viewBox="0 0 18 18" svgClass="icon"></svg-icon>
        Save Report
      </button>
      <kendo-dialog *ngIf="openSaveReportDialog" (close)="closeReport()">
        <div [style.width.PX]="450" class="savereport">
          <div class="d-flex flex-column border-bottom">
            <h4 class="fw-bold">Save Report</h4>
            <p class="visual_subtitle">Save report settings to the system.</p>
          </div>
          <div class="mt-4">
            <kendo-label text="Report Name">
              <kendo-textbox fillMode="flat" [style.width.%]="90" [(ngModel)]="reportName"
                             [clearButton]="false" placeholder="Enter Name">
              </kendo-textbox>
            </kendo-label>
          </div>
          <div class=" action-btn mt-3">
            <button type="button" class="btn btn-action-primary btn-above-overlay ms-2 m-3"
                    (click)="saveReports()">
              Save
            </button>
            <button type="button" class="btn btn-action-secondary btn-above-overlay ms-2 m-3"
                    (click)="closeReport()">
              Cancel
            </button>
          </div>
        </div>
      </kendo-dialog>
    </div>
    <div class="py-2">
      <button class="report-header-action"  (click)="exportReportData()">
        <svg-icon name="export" viewBox="0 0 18 18" svgClass="icon"></svg-icon>
        Export
      </button>
    </div>
    <div class="py-2">
      <button class="report-header-action" (click)="toggleFilterVisibility()">
        <svg-icon name="filter" viewBox="0 0 18 12" svgClass="icon"></svg-icon>
        Filter
      </button>
    </div>
  </div>

  <div class="content-width" [class.content-width-padded]="showFilter">
    <kendo-expansionpanel #filterExpansionPanel class="hide-expander-header hide-expander-padding">
      <div class="dashboard" style="background-color: #F4F8FA;">
        <div class="action-row d-flex flex-row">
          <div class="py-2 width300 d-flex justify-content-center daterange">
            <div class="width100percent">
              <kendo-label text="Year(s):" class="years-control muted-small">
                <kendo-multiselect [data]="years"  [(ngModel)]="yearsSelected"></kendo-multiselect>
              </kendo-label>
            </div>
          </div>
          <div class="py-2 width500 d-flex justify-content-center">
            <div class="width100percent">
              <kendo-label text="Month(s):" class="months-control muted-small">
                <kendo-multiselect [data]="months"  [(ngModel)]="monthsSelected" textField="name" valueField="number" [valuePrimitive]="true">
                </kendo-multiselect>
              </kendo-label>
            </div>
          </div>
        </div>
        <br />
        <div class="action-group">
          <button class="btn btn-action-primary" (click)="applyFilters()"
                  [disabled]="enableSaveButton">
            Apply
          </button>
          <button class="btn btn-action-secondary" (click)="clearFilters()">Clear</button>
        </div>
      </div>
    </kendo-expansionpanel>
  </div>
</div>
