import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { PercentPipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ForecastDataExportService {

  createWorkbook(): ExcelJS.Workbook {
    return new ExcelJS.Workbook();
  }

  addWorksheet(workbook: ExcelJS.Workbook, name: string, data: any[], rowsConfiguration: any[]): ExcelJS.Worksheet {
    const worksheet = workbook.addWorksheet(name);
    const transformedData = this.transformData(data, rowsConfiguration);

    // Set the columns with the month names as column headers
    const columns = [{ header: ' ', key: 'metric', width: 15 }];

    //// Add months as columns headers   
    const months = Object.keys(transformedData[0]).filter(key => key !== 'metric');
    months.forEach(month => {
      columns.push({ header: month, key: month, width: 15 });
    });

    worksheet.columns = columns;

    transformedData.forEach(row => {
      worksheet.addRow(row);
    });

    return worksheet;
  }

  saveWorkbook(workbook: ExcelJS.Workbook, fileName: string): void {
    // Save the workbook to a blob
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${fileName}.csv`);
    });
  }

  private transformData(data: any[], rowsConfiguration: any[]): any[] {
    // Prepare the row structure
    let rows: any[] = [];
    rowsConfiguration.forEach((item: any) => {
      rows.push({ metric: item.header, ...this.extractMonthValues(data, item.key, item.convertFiled) })
    });
    return rows;
  }

  private extractMonthValues(data: any[], metric: string, convertFiled: boolean | undefined | null): any {
    const monthValues: { [key: string]: string } = {};
    if (convertFiled === undefined || convertFiled === null) {
      convertFiled = true;
    }
    data.forEach(item => {
      if (convertFiled == true) {
        monthValues[item.monthName] = this.transform(item[metric]);
      }
      else {
        monthValues[item.monthName] = item[metric];
      }
    });
    return monthValues;
  }

  private transform(value: number): string {
    if (value === null || value === undefined) {
      return '';
    }
    let roundedValue = value.toFixed(2);
    return `${roundedValue}%`;
  }
}
