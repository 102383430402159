import { Component } from '@angular/core';
import { KpiDataService } from '../../../services/kpi-data.service';
import { BehaviorSubject } from 'rxjs';
import { SubSink } from 'subsink';
import { cloneDeep } from 'lodash';
import { FirstMileLastMileAccurate, FirstMileLastMileDto, FirstMileLastMileInAccurate } from '../../../../../models/first-mile-last-mile-report';
import { PercentPipe } from '@angular/common';

@Component({
  selector: 'app-forecast-first-last-mile',
  templateUrl: './forecast-first-last-mile.component.html',
  styleUrl: './forecast-first-last-mile.component.scss',
  providers: [PercentPipe]
})
export class ForecastFirstLastMileComponent {

  fulfillmentData: FirstMileLastMileAccurate[] = [];
  accuracyData: FirstMileLastMileInAccurate[] = [];
  private sub = new SubSink();

  isFirstLastMileReportLoading$ = new BehaviorSubject<boolean>(true);
  width = "300";
  height = "280";
  type = "msline";
  dataFormat = "json";
  dataSource: any;
  constructor(private kpiDataService: KpiDataService,
    private percentPipe: PercentPipe) {
  }

  GetMsLineChartDataSource() {

    let reportSource = {
      chart: {
        caption: "",
        yaxisname: "",
        subcaption: "",
        showhovereffect: "1",
        showlegend: "0",
        numbersuffix: "%",
        drawcrossline: "1",
        drawAnchors: "0",
        plottooltext: "<b>$dataValue</b> of $seriesName",
        theme: "fusion"
      },
      categories: [],
      dataset: [
        {
          seriesname: "Fulfillment",
          color: "#1B365D",
          data: []
        },
        {
          seriesname: "Min Fulfillment",
          color: "#1B365D",
          dashed: "1",
          data: []
        },
        {
          seriesname: "Accuracy",
          color: "#F34444",
          data: []
        },
        {
          seriesname: "Min Accuracy",
          color: "#F34444",
          dashed: "1",
          data: []
        }
      ]
    };
    return reportSource;
  }

  ngOnInit(): void {
    this.sub.sink = this.kpiDataService.getFirstMileLastMileReport().subscribe((dwellKpi: FirstMileLastMileDto) => {
      this.loadFirstLastMileReport(dwellKpi);
      this.isFirstLastMileReportLoading$.next(false);
    });
  }

  loadFirstLastMileReport(dataset: FirstMileLastMileDto | null) {
    if (dataset == null) return;
    this.fulfillmentData = dataset.accurate;
    this.accuracyData = dataset.inAccurate;
    this.mapReportData(dataset);
  }

  mapReportData(dataset: FirstMileLastMileDto) {
    dataset.accurateReport = dataset.accurateReport.sort((a, b) => a.year - b.year);
    dataset.inAccurateReport = dataset.inAccurateReport.sort((a, b) => a.year - b.year);
    var reportDataSource = cloneDeep(this.GetMsLineChartDataSource());
    let categories = [{ category: dataset.accurateReport.map(item => ({ label: item.year.toString() })) }];

    let fulfillmentList = dataset.accurateReport.map(item => ({ value: this.percentPipe.transform(item.fulfillment, '1.2-2') }));
    let minFulfillmentList = dataset.accurateReport.map(item => ({ value: this.percentPipe.transform(item.minFulfillment, '1.2-2') }));
    let accuracyList = dataset.inAccurateReport.map(item => ({ value: this.percentPipe.transform(item.accuracy, '1.2-2') }));
    let minAccuracyList = dataset.inAccurateReport.map(item => ({ value: this.percentPipe.transform(item.minAccuracy, '1.2-2') }));

    (reportDataSource.categories as { category: { label: string }[] }[]) = categories;

    reportDataSource.dataset.forEach((line: any) => {
      if (line.seriesname == "Fulfillment") {
        line.data = fulfillmentList;
      }
      if (line.seriesname == "Min Fulfillment") {
        line.data = minFulfillmentList;
      }
      if (line.seriesname == "Accuracy") {
        line.data = accuracyList;
      }
      if (line.seriesname == "Min Accuracy") {
        line.data = minAccuracyList;
      }
    });
    this.dataSource = reportDataSource;
  }
}
