import { Component, OnDestroy, OnInit } from '@angular/core';
import { DatePipe, formatDate } from '@angular/common';
import { DownloadForecast, ImportExport } from '../../../constants/forecast';
import { toInteger } from 'lodash';
import { KpiDataService } from '../services/kpi-data.service';
import { ForecastSummaryReport } from '../../../models/forecast-summary-report';
import { BehaviorSubject, map, mergeMap, Observable } from 'rxjs';
import { SubSink } from 'subsink';
import { NotificationRef, NotificationService } from '@progress/kendo-angular-notification';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import { UserService } from '../../../../admin/services/user.service';
import { User } from '../../../../admin/models/user';
import { CustomerGroupUserRole } from '../../../../admin/models/customer-group-user-role';
import { Role } from '../../../constants/role';


@Component({
  selector: 'app-forecast-dashboard-landing',
  templateUrl: './forecast-dashboard-landing.component.html',
  styleUrl: './forecast-dashboard-landing.component.scss',
  providers: [DatePipe]
})
export class ForecastDashboardLandingComponent implements OnInit, OnDestroy {
  user?: User;

  isLoadingUserInformation$ = new BehaviorSubject<boolean>(false);

  private sub = new SubSink();

  role = Role;

  isForecastSummaryLoading$ = new BehaviorSubject<boolean>(true);

  currentdate: Date = new Date();
  formattedWeek: string = '';
  startDate!: Date;
  endDate!: Date;
  temp_data: any = null;
  currentView = '';
  title = '';
  rangeDates: Date[] = [];
  subTitle = '';
  fileSize = 0;
  fileName = '';
  fileSelected = false;
  openSecondDialog = false;
  showErrorMessage = false;
  selectedFile: any;
  public openDialog = false;
  // Sample array for options
  options = [
    'Daily',
    'Weekly',
    'Monthly',
  ];

  years = [
    '2023',
    '2024',
    '2025',
  ];

  private activeNotification?: NotificationRef;
  performance_date: string = "04/24/2024";
  public form: FormGroup = new FormGroup({
    forecastTypeForm: new FormControl(),
    forecastYear: new FormControl(),
    downloadForecast: new FormControl()

  });


  data = [{
    status: 'Today', dateMonth: this.datePipe.transform(new Date(), 'EEEE, MMMM d'), info: [{ label: 'Empty on Hand', value: '0 Railcars', icon: 'vector' },
    { label: 'In-Transit', value: '0 Railcars', icon: 'timeline' }, { label: 'Forecasted', value: '- Shipments', icon: 'large-donut' },
    { label: 'Ready to Ship', value: '- Railcars', icon: 'geo-alt-fill' }]
  },
  {
    status: 'Weekly Summary', dateMonth: this.calculateWeekRange(), info: [{ label: 'Forecasted', value: '- car/week', icon: 'line-chart' },
    { label: 'fullfilled', value: '- %/week', icon: 'box-fill' }, { label: 'Missed Orders', value: '- Orders/Week', icon: 'box' }, { label: 'Average Cycle Times', value: '- Hours', icon: 'time-fill' }]
  }];

  constructor(private kpiDataService: KpiDataService,
    private notificationService: NotificationService,
    public userService: UserService,
    private datePipe: DatePipe) {
    this.performance_date = formatDate(Date(), 'M/dd/yyyy', 'en-US');
  }

  private intervalid: any;
  ngOnInit(): void {
    this.fetchForecastSummaryReport();
    this.isLoadingUserInformation$.next(true);
    this.sub.sink = this.userService.getCurrentUser().subscribe((user) => {
      this.user = user;
      this.isLoadingUserInformation$.next(false);
      this.enableDiableUserPermissions();
    });
    this.intervalid = setInterval(() => {
      this.fetchForecastSummaryReport();
    }, 60000);
  }

  fetchForecastSummaryReport() {
    this.sub.sink = this.kpiDataService.getForecastSummaryReport().subscribe((dwellKpi: ForecastSummaryReport) => {
      this.loadForecastSummaryLoadingReport(dwellKpi);
      this.isForecastSummaryLoading$.next(false);
    });
  }

  isAssociationUser: boolean = false// update to true for testing in local to check permsions enabled;
  selectedOption: string = 'ASSOCIATION';
  enableDiableUserPermissions() {
    this.isAssociationUser = this.user?.customerGroupUserRoles?.some(x => x.customerGroupName === "WCSC") ?? false;
    this.selectedOption = this.isAssociationUser ? 'ASSOCIATION' : 'MY_DATA';
  }

  onOptionChange(event: any): void {
  }

  calculateWeekRange() {
    var currentDate: Date = new Date();
    const dayOfWeek = currentDate.getDay();
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() - dayOfWeek + 1);

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    this.startDate = startOfWeek;
    this.endDate = endOfWeek;
    const start = this.datePipe.transform(this.startDate, 'MMMM d');
    const end = this.datePipe.transform(this.endDate, 'MMMM d');

    var formattedWeek = `${start} - ${end}`;
    return formattedWeek;
  }

  loadForecastSummaryLoadingReport(dwellKpi: ForecastSummaryReport) {
    this.data.forEach((item: any) => {

      if (item.status == 'Today') {
        item.info.forEach((line: any) => {
          if (line.label == 'Empty on Hand') {
            line.value = dwellKpi.emptyOnHand + ' Railcars';
          }
          if (line.label == 'In-Transit') {
            line.value = dwellKpi.inTransit + ' Railcars';
          }
          if (line.label == 'Forecasted') {
            line.value = dwellKpi.forecasted + ' Shipments';
          }
          if (line.label == 'Ready to Ship') {
            line.value = dwellKpi.readyToShip + ' Railcars';
          }
        });
      } else {
        item.info.forEach((line: any) => {
          if (line.label == 'Forecasted') {
            line.value = dwellKpi.weeklyForecasted + ' cars/week';
          }
          if (line.label == 'fullfilled') {
            line.value = dwellKpi.fullFilledPercentage + ' %/week';
          }
          if (line.label == 'Missed Orders') {
            line.value = dwellKpi.missedOrder + ' orders/week';
          }
          if (line.label == 'Average Cycle Times') {
            line.value = dwellKpi.averageCycleDays.toFixed(2) + ' -Hours';
          }
        });
      }
    });
  }
  valueChange(event: string) {
    this.showErrorMessage = false;
    // this.form.markAllAsTouched();

  }

  ngOnDestroy(): void {
    if (this.intervalid) {
      clearInterval(this.intervalid)
    }
    this.sub.unsubscribe();    
  }

  public itemDisabled(itemArgs: { dataItem: string; index: number }) {
    return itemArgs.index === -1;
  }

  get isforecastSelected() {
    return this.form.value.forecastTypeForm !== null && this.form.value.forecastTypeForm !== '' && this.form.value.forecastTypeForm !== 'Select';
  }

  get isDownloadForecastSelected() {
    return this.form.value.downloadForecast !== null && this.form.value.downloadForecast !== '' && this.form.value.downloadForecast !== 'Select';
  }

  get isYearSelected() {
    return this.form.value.forecastYear !== null && this.form.value.forecastYear !== '' && this.form.value.forecastYear !== 'Select';
  }

  public close(): void {
    this.openDialog = false;
    this.fileSelected = false;

    if (this.currentView === 'download') {
      this.resetDownloadTemplate();
    }
    else {
      this.resetImportTemplate();
      this.showErrorMessage = false;
      this.form.reset();
    }
  }

  closeSecondDialog() {
    this.openSecondDialog = false;
    this.openDialog = true;
  }
  importFile() {
    this.openSecondDialog = false;
    this.openDialog = false;
    const formData = new FormData();
    formData.append('file', this.selectedFile);
    formData.append('forecastType', this.form.value.forecastTypeForm);
    this.kpiDataService.uploadForecaseTemplate(formData).subscribe((res: any) => {
      if (res.message === "Success") {
        this.showHideImportStatusNotification(true);


      } else {
        this.showHideImportStatusNotification(false);
      }
    })
    this.resetImportTemplate();

    this.sub.sink = this.kpiDataService.getForecastSummaryReport().subscribe((dwellKpi: ForecastSummaryReport) => {
      this.loadForecastSummaryLoadingReport(dwellKpi);
      this.isForecastSummaryLoading$.next(false);
    });

  }

  resetImportTemplate() {
    this.fileSize = 0;
    this.fileName = '';
    this.fileSelected = false;

  }

  private showHideImportStatusNotification(isValid: boolean) {
    if (this.activeNotification) {
      this.activeNotification.hide();
    }

    this.activeNotification = this.notificationService.show({
      content: isValid ? 'Success! A new forecast has been uploaded to the system.' : 'Unsuccessful. Something went wrong. Please try again.',
      cssClass: ["notification-colors", "notification-font"],
      type: { style: isValid ? "success" : "warning", icon: true },
      animation: {
        type: 'slide',
        duration: 500,
      },
      closable: true,
      position: { horizontal: 'center', vertical: 'bottom' },
    });
    setTimeout(() => {
      if (this.activeNotification) {
        this.activeNotification.hide();
      }
    }, 5000);
  }

  get disableSaveBtn() {
    let isdisabled: boolean = true;
    if (this.currentView === 'import') {
      isdisabled = !this.fileSelected;
    }
    else {
      isdisabled = !(this.isDownloadForecastSelected && this.isYearSelected);
    }
    return isdisabled;
  }

  resetDownloadTemplate() {
    this.form.reset();
  }

  onSave() {

    if (this.currentView === 'import') {

      if (this.form.invalid) {
        this.form.markAllAsTouched()
      } else {
        this.openSecondDialog = true;
        this.openDialog = false;
      }

    } else {
      this.sub.sink = this.kpiDataService.getForecastTemplate(this.form.value.downloadForecast.toLocaleLowerCase()).subscribe((response: HttpResponse<Blob>) => {
        const fileName = response.headers.get("Content-Disposition")?.split(";")[1].trim().split("=")[1];
        if (response.body) {
          const blob = new Blob([response.body], { type: "text/csv" });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName ? fileName : 'sample';
          link.click()
          window.URL.revokeObjectURL(link.href);

        }
      })

      this.openDialog = false;

    }
  }

  public open(event: string): void {

    if (event === 'import') {
      this.currentView = 'import';
      this.title = ImportExport.Title;
      this.subTitle = ImportExport.SubTitle

    } else {
      this.currentView = 'download';
      this.title = DownloadForecast.Title;
      this.subTitle = DownloadForecast.SubTitle
    }

    this.openDialog = true;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    this.onSelectFile(event);
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();

  }

  onSelectFile(event: any) {

    this.fileName = (event.target as any)?.files?.[0].name ? (event.target as any).files[0].name : event.dataTransfer?.files[0].name;
    const type = (event.target as any).files?.[0].type ? (event.target as any).files[0].type : event.dataTransfer?.files[0].type;

    if (['text/csv', 'application/vnd.ms-excel'].includes(type)) {
      this.showErrorMessage = false;
      this.fileSelected = true;
      this.selectedFile = (event.target as any)?.files?.[0] ? (event.target as any).files[0] : event.dataTransfer?.files[0];

      // Get length of file in bytes
      this.fileSize = this.selectedFile.size;
      // Convert the bytes to Kilobytes (1 KB = 1024 Bytes)
      this.fileSize = parseFloat((this.fileSize / 1024).toFixed(2));
      this.fileSize = Math.ceil(this.fileSize);


    } else if (!['text/csv', 'application/vnd.ms-excel'].includes(type)) {
      this.showErrorMessage = true;

      event.target.files = '';
      event.dataTransfer.files = '';
    } else {
      this.form.markAllAsTouched();
    }
  }
}

