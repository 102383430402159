<div class="forecast-demand-report">
  <div class="d-flex justify-content-between forecast-demand-border">
    <app-forecast-demand-report-filters [reportTitle]="reportTitle"
      (sendFilterDataToParent)="handleFilterData($event)"></app-forecast-demand-report-filters>
  </div>
  <div class="row d-flex justify-content-between forecast-demand-border report-border-margin">
    <div class="col-4">
      <div class="visual_title ps-1">Forecast Demand</div>
      <div class="visual_subtitle ps-1">Description</div>
    </div>
    <div>
      <div class="legend">
        <span class="dot-inaccurate"></span>
        <span class="dot-inaccurate"></span>
        <span class="dot-inaccurate"></span>
        <span class="dot-inaccurate"></span>
        <span class="padding-3px">Forecasted</span>
        &nbsp;
        &nbsp;
        <span class="dot-planned"></span>
        <span>Shipped</span>
        &nbsp;
        &nbsp;
        <span class="dot-missed"></span>
        <span>Forecast Accuracy</span>

      </div>
    </div>
    <div class="w-100 d-flex justify-content-center" *ngIf="isLoading">
      <div class="spinner-border d-flex justify-content-around" role="status">
      </div>
    </div>
    <div class="d-flex justify-content-around" id="forcastSummaryReport" #forcastSummaryReport>
      @if (dataSource && !noData) {
      <fusioncharts [width]="width" [height]="height" [type]="type" [dataFormat]="dataFormat" [dataSource]="dataSource"
                    [renderAt]="'forcastSummaryReport'">
      </fusioncharts>
      }
      @if(noData && !isLoading)
      {
      <div class="parentdiv">
        <div>
          <div class="nodataheader">No Data</div>
          <div class="nodatacontent">Select a report or adjust filters to view data.</div>
        </div>
      </div>
      }
    </div>
    <div class="h-100 mb-2 scrolltablediv">
      <div class="col-12 card-bg table-container">
        <div class="w-100 d-flex justify-content-center" *ngIf="isLoading">
          <div class="spinner-border d-flex justify-content-around" role="status">
          </div>
        </div>
        <table *ngIf="!isLoading" class="borderenabled w-100 firstmilelastmile-table">
          <tr>
            <td class="padding-3px tablerptclmn-width"> <label></label></td>
            <td class="padding-3px tablerptclmn-data-width" *ngFor="let dt  of fulfillmentData">
              <label class=" fw-light-lbl">{{dt.monthName | uppercase}}</label>
            </td>
          </tr>
          <tr>
            <td class="padding-3px tablerptclmn-width"> <label class="fw-light-lbl">Forecasted</label></td>
            <td class="padding-3px tablerptclmn-data-width" *ngFor="let dt  of fulfillmentData">
              <label class="fw-normal">{{dt.monthlyForecast }}</label>
            </td>
          </tr>
          <tr>
            <td class="padding-12px tablerptclmn-width"> <label class="fw-light-lbl">Shipped</label></td>
            <td class="padding-3px tablerptclmn-data-width" *ngFor="let dt  of fulfillmentData">
              <label class="fw-normal">{{dt.releasedCars }}</label>
            </td>
          </tr>
          <tr>
            <td class="padding-12px tablerptclmn-width"> <label class="fw-light-lbl">Forecast Accuracy</label></td>
            <td class="padding-3px tablerptclmn-data-width" *ngFor="let dt  of fulfillmentData">
              <label class="fw-normal">{{dt.ordersMissed}}</label>
            </td>
          </tr>
          <tr>
            <td class="padding-3px tablerptclmn-width"> <label class="fw-light-lbl">Accuracy%</label></td>
            <td class="padding-3px tablerptclmn-data-width" *ngFor="let dt  of fulfillmentData">
              <label class="fw-normal">{{dt.accuracy}}%</label>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
