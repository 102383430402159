import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ExpansionPanelComponent } from "@progress/kendo-angular-layout";
import { SelectionRange } from "@progress/kendo-angular-dateinputs";
import { Report } from '../../../../../../dashboard/models/report/report';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable, filter, buffer, map, catchError, of } from "rxjs";
import { QueryObserverResult } from '@tanstack/query-core';
import { SubSink } from "subsink";
import { ReportDataService } from '../../../../../../dashboard/submodules/filters/services/report-data.service';
import { ForecastReportFormService } from '../../../../../services/forecast-report-form.service';
import { ForecastReportService } from '../../../../../services/forecast-report.service';
import { UserService } from '../../../../../../admin/services/user.service';
import { User } from '../../../../../../admin/models/user';
import { ForecastDataExportService } from '../../../../../services/forecast-data-export.service';
import { KpiDataService } from '../../../../landing/services/kpi-data.service';
import { sortBy } from 'sort-by-typescript';

@Component({
  selector: 'app-forecast-demand-report-filters',
  templateUrl: './forecast-demand-report-filters.component.html',
  styleUrl: './forecast-demand-report-filters.component.scss'
})
export class ForecastDemandReportFiltersComponent implements OnInit {

  @Input() reportTitle: string = '';

  reportName: string = '';
  @ViewChild('filterExpansionPanel') filterExpansionPanel?: ExpansionPanelComponent;

  reportdata: any[] = [];
  originalReportdata: any[] = [];
  reportQueryResult$?: Observable<QueryObserverResult<Report[], Error>>;
  isLoadingUserInformation$ = new BehaviorSubject<boolean>(false);
  currentEdittingRecord: any;
  user?: User;
  selectedReport: any = { id: 0, name: "Select Report" };
  private sub = new SubSink();


  years: number[] = [];
  months: Month[] = Months;
  yearsSelected: number[] = [];
  monthsSelected: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  buildYearList(yearToAdd: number) {
    this.years = [];
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 1);
    let currentYear = currentDate.getFullYear();
    while (yearToAdd <= currentYear) {
      this.years.push(yearToAdd);
      yearToAdd += 1;
    }

    this.years = this.years.sort(sortBy('-'));
  }

  buildSelectedYearList() {
    this.yearsSelected = [];

    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 1);
    let currentYear = currentDate.getFullYear();
    let yearsToAdd = currentDate.getFullYear() - 2;
    while (yearsToAdd <= currentYear) {
      this.yearsSelected.push(yearsToAdd);
      yearsToAdd += 1;
    }
  }
  constructor(private formBuilder: FormBuilder,
    private reportFormService: ForecastReportFormService,
    private forecastReportService: ForecastReportService,
    private exportService: ForecastDataExportService,
    private kpiDataService: KpiDataService,
    private userService: UserService,
    private reportDataService: ReportDataService) {
  }

  ngOnInit(): void {
    this.reportQueryResult$ = this.reportFormService.getAllReportsForType(this.reportTitle).result$;
    this.hideAllReportEditMenu();
    this.buildYearList(2021);
    this.buildSelectedYearList();
    this.isLoadingUserInformation$.next(true);
    this.sub.sink = this.userService.getCurrentUser().subscribe((user) => {
      this.user = user;
      this.isLoadingUserInformation$.next(false);
    });

    this.sendData(this.getFilteredObject());
  }

  hideAllReportEditMenu() {
    this.sub.sink = this.reportQueryResult$?.subscribe((result) => {
      this.reportdata = result.data ?? [];
      this.options = [{ id: 0, name: "Select Report" }];
      for (let report of this.reportdata as any[]) {
        this.options.push({ id: report.id, name: report.name });
        report.show = false;
      }
      this.originalReportdata = cloneDeep(this.reportdata);
    });
  }

  exportReportData() {
    var reportFilterData = JSON.parse(this.getFilteredObject());
    this.exportExcelFilteredReportData(reportFilterData);
  }

  exportSelectedReportData(item: any) {
    var reportFilterData = JSON.parse(item.filters);
    item.show = false;
    this.exportExcelFilteredReportData(reportFilterData);
  }

  exportExcelFilteredReportData(reportFilterData: any) {
    switch (this.reportTitle) {
      case 'Accuracy Fulfillment':
        this.exportFirstMileLastMileYearReport(reportFilterData);
        break;
      case 'Switching Performance':
        this.exportSwitchingPerformanceYearReport(reportFilterData);
        break;
      case 'Volume Demand':
        this.exportVolumeDemandYearReport(reportFilterData);
        break;
      case 'Forecast Demand':
        this.exportForecastDemandYearReport(reportFilterData);
        break;
    }
  }

  exportForecastDemandYearReport(reportFilterData: any) {
    this.sub.sink = this.kpiDataService.GetForecastDemandYearReport(reportFilterData).subscribe((dwellKpi: any[]) => {
      if (dwellKpi) {
        let workbook = this.exportService.createWorkbook();
        this.exportService.addWorksheet(workbook, this.reportTitle, dwellKpi, [
          { header: 'Forecasted', key: 'monthlyForecast', convertFiled: false },
          { header: 'Shipped', key: 'releasedCars', convertFiled: false },
          { header: 'Forecast Accuracy', key: 'ordersMissed', convertFiled: false },
          { header: 'Accuracy%', key: 'accuracy', convertFiled: true }
        ]);
        this.exportService.saveWorkbook(workbook, this.reportTitle);
      }
    });
  }

  exportVolumeDemandYearReport(reportFilterData: any) {
    this.sub.sink = this.kpiDataService.getVolumeDemandYearReport(reportFilterData).subscribe((dwellKpi: any[]) => {
      if (dwellKpi) {
        let workbook = this.exportService.createWorkbook();
        this.exportService.addWorksheet(workbook, this.reportTitle, dwellKpi, [
          { header: 'Fulfillment', key: 'fulfillment' },
          { header: 'Max Fulfillment', key: 'maxFulfillment' },
          { header: 'Min Fulfillment', key: 'minFulfillment' }
        ]);
        this.exportService.saveWorkbook(workbook, this.reportTitle);
      }
    });
  }

  exportSwitchingPerformanceYearReport(reportFilterData: any) {
    this.sub.sink = this.kpiDataService.getSwitchingPerformanceYearReport(reportFilterData).subscribe((dwellKpi: any[]) => {
      if (dwellKpi) {
        let workbook = this.exportService.createWorkbook();
        this.exportService.addWorksheet(workbook, this.reportTitle, dwellKpi, [
          { header: 'Performed as Planned', key: 'performedAsPlanned' },
          { header: 'Inaccurate', key: 'inaccurate' },
          { header: 'Missed', key: 'missed' }
        ]);
        this.exportService.saveWorkbook(workbook, this.reportTitle);
      }
    });
  }

  exportFirstMileLastMileYearReport(reportFilterData: any) {
    this.sub.sink = this.kpiDataService.GetFirstMileLastMileYearReport(reportFilterData).subscribe((dwellKpi: any[]) => {
      if (dwellKpi) {
        let workbook = this.exportService.createWorkbook();
        this.exportService.addWorksheet(workbook, this.reportTitle, dwellKpi, [
          { header: 'Fulfillment', key: 'fulfillment' },
          { header: 'Max Fulfillment', key: 'maxFulfillment' },
          { header: 'Min Fulfillment', key: 'minFulfillment' },
          { header: 'Accuracy', key: 'accuracy' },
          { header: 'Max Accuracy', key: 'maxAccuracy' },
          { header: 'Min Accuracy', key: 'minAccuracy' }
        ]);
        this.exportService.saveWorkbook(workbook, this.reportTitle);
      }
    });
  }

  get userEmail(): string {
    return !!this.user ? this.user.email : '';
  }

  saveNewReport() {
    var filterobject = this.getFilteredObject();
    this.sub.sink = this.reportFormService.saveNewReport(filterobject, this.reportName, this.reportTitle, this.userEmail).pipe(
      map((result: any) => {
        return true;
      }),
      catchError((err: boolean) => of(false))
    ).subscribe((success: boolean) => {
      if (success) {
        this.reportQueryResult$ = this.reportFormService.getAllReportsForType(this.reportTitle).result$;
        this.hideAllReportEditMenu();
      }
      else {
      }
    });
  }

  isFiltered: boolean = false;
  openSaveReportDialog: boolean = false;
  options: any[] = [{ id: 0, name: "Select Report" }];
  @Output() sendFilterDataToParent = new EventEmitter<string>();
  sendData(filterdata: string) {
    this.sendFilterDataToParent.emit(filterdata);
  }

  reportNameDisabled(itemArgs: { dataItem: any; index: number }) {
    return (itemArgs.dataItem.id == null || itemArgs.dataItem.id <= 0);
  }

  valueChange(event: any) {
    if (event != null && event.id != 0) {
      this.reportdata.forEach((item: any) => {
        if (item.id === event.id) {
          var x = JSON.parse(item.filters);
          this.monthsSelected = x.months;
          this.yearsSelected = x.years;
          this.sendData(this.getFilteredObject());
        }
      });
    }
    else {
      this.monthsSelected = [];
      this.yearsSelected = [];
    }
  }

  public closeReport(): void {
    this.openSaveReportDialog = false;
  }

  onSave() {
    this.openSaveReportDialog = false;
  }

  saveReports() {
    if (this.selectedReport.id && this.selectedReport.id > 0) {
      var filterobject = this.getFilteredObject();
      this.updateEditReport(filterobject, this.reportName, this.reportTitle, this.selectedReport.id, this.selectedReport.createdBy);
    }
    else {
      this.saveNewReport();
    }
    this.opened = false;
    this.openSaveReportDialog = false;
    this.showFilter = false;
    this.reportName = "";
    this.selectedReport = { id: 0, name: "Select Report" };
    this.monthsSelected = [];
    this.yearsSelected = [];
    this.isFiltered = false;
    if (this.filterExpansionPanel?.expanded == true) {
      this.filterExpansionPanel?.toggle();
    }
  }

  updateReports() {
    var editedItem: any;
    this.reportdata.forEach((item: any) => {
      if (item.editRecord === true) {
        editedItem = item;
      }
    });
    this.updateEditReport(editedItem.filters, editedItem.name, this.reportTitle, editedItem.id, editedItem.createdBy);
  }

  updateEditReport(filters: string, name: string, reportTitle: string, id: number, createdBy: string) {
    this.sub.sink = this.reportFormService.saveEditingReport(filters, name, reportTitle, id, createdBy).pipe(
      map((result: any) => {
        return true;
      }),
      catchError((err: boolean) => of(false))
    ).subscribe((success: boolean) => {
      if (success) {
        this.reportQueryResult$ = this.reportFormService.getAllReportsForType(this.reportTitle).result$;
        this.hideAllReportEditMenu();
      }
    });
    this.opened = false;
    this.openSaveReportDialog = false;
  }

  get showHideManageReportData() {
    return this.reportdata && this.reportdata.length > 0;
  }

  get filteredReportData() {
    return this.reportdata?.filter((item: any) => !item.deleteRecord);
  }

  editReportRecord(rowData: any) {
    this.reportdata.forEach((item: any) => {
      if (item.editRecord === true) {
        item.name = this.currentEdittingRecord.name;
      }
      item.editRecord = false;
    });
    this.currentEdittingRecord = JSON.parse(JSON.stringify(rowData));
    rowData.show = false;
    rowData.editRecord = true;
  }

  get enableManageReportSave(): boolean {
    return !this.reportdata?.some((item: any) => (item.editRecord === true || item.deleteRecord === true));
  }

  showDeleteReportDailog: boolean = false;
  isDeleting: boolean = false;
  deletingRecord: any;
  openDeleteConformaionDialog(rowData: any) {
    this.showDeleteReportDailog = true;
    this.deletingRecord = cloneDeep(rowData);
  }

  cancelDeleteClick() {
    this.showDeleteReportDailog = false;
    this.deletingRecord = null;
    this.reportdata.forEach((item: any) => {
      item.show = false;
    });
  }
  closeManageReportWindow() {
    this.showDeleteReportDailog = false;
    this.deletingRecord = null;
    this.reportdata.forEach((item: any) => {
      item.show = false;
    });
  }

  confirmDeleteClick() {
    this.isDeleting = true;
    this.deleteReportRecord(this.deletingRecord);
  }

  deleteReportRecord(rowData: any) {
    this.confirmDeleteClick1(rowData.id);
  }

  confirmDeleteClick1(id: any) {
    this.sub.sink = this.reportFormService.deleteReport(id).subscribe(() => {
      this.deletingRecord = null;
      this.showDeleteReportDailog = false;
      this.isDeleting = false;
      this.reportQueryResult$ = this.reportFormService.getAllReportsForType(this.reportTitle).result$;
      this.hideAllReportEditMenu();
    });
  }

  public openReportSaveDialog(): void {
    if (this.selectedReport.id > 0) {
      this.reportName = this.selectedReport.name;
    }
    this.openSaveReportDialog = true;
  }

  toggleReportListEditMenu(reportToOpen: any) {
    for (let report of this.reportdata as any[]) {
      if (report.id != reportToOpen.id) {
        report.show = false;
      }
    }
    reportToOpen.show = !reportToOpen.show;
  }

  get enableSaveReport(): boolean {
    return !this.isFiltered;
  }

  get enableSaveButton(): boolean {
    return !this.enableDateFilterSaveBtn();
  }

  enableDateFilterSaveBtn(): boolean {
    return this.monthsSelected != null &&
      this.monthsSelected.length > 0 &&
      this.yearsSelected != null &&
      this.yearsSelected.length > 0;
  }

  getFilteredObject() {
    return JSON.stringify({ months: this.monthsSelected, years: this.yearsSelected });
  }

  applyFilters() {
    this.sendData(this.getFilteredObject());
    this.isFiltered = true;
  }

  clearFilters() {
    this.monthsSelected = [];
    this.yearsSelected = [];
    this.isFiltered = false;
    this.selectedReport = { id: 0, name: "Select Report" };
  }

  public opened = false;
  public close(): void {
    this.reportdata = cloneDeep(this.originalReportdata);
    this.opened = false;
  }

  public open(): void {

    this.opened = true;
  }

  showFilter: boolean = false;
  toggleFilterVisibility() {
    this.showFilter = !this.showFilter;
    if (this.filterExpansionPanel?.expanded != this.showFilter) {
      this.filterExpansionPanel?.toggle();
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}

export interface Month {
  name: string,
  number: number
}

export const Months: Month[] = [
  {
    name: 'January',
    number: 1
  },
  {
    name: 'February',
    number: 2
  },
  {
    name: 'March',
    number: 3
  },
  {
    name: 'April',
    number: 4
  },
  {
    name: 'May',
    number: 5
  },
  {
    name: 'June',
    number: 6
  },
  {
    name: 'July',
    number: 7
  },
  {
    name: 'August',
    number: 8
  },
  {
    name: 'September',
    number: 9
  },
  {
    name: 'October',
    number: 10
  },
  {
    name: 'November',
    number: 11
  },
  {
    name: 'December',
    number: 12
  },
]
