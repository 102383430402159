import { PercentPipe } from '@angular/common';
import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, HostListener } from '@angular/core';
import { KpiDataService } from '../../../../landing/services/kpi-data.service';
import { cloneDeep } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { SubSink } from 'subsink';
import { ForecastDemandFullReport } from '../../../../../models/forecast-summary-report';
@Component({
  selector: 'app-forecast-demand-report',
  templateUrl: './forecast-demand-report.component.html',
  styleUrl: './forecast-demand-report.component.scss',
  providers: [PercentPipe]
})
export class ForecastDemandReportComponent implements OnInit {

  reportTitle = "Forecast Demand";
  width = "85%";
  height = "280";
  isLoading = false;
  type = "msline";
  dataFormat = "json";
  dataSource: any;
  noData: boolean = true;
  private sub = new SubSink();
  isForecastDemandReportByLoading$ = new BehaviorSubject<boolean>(true);
  @ViewChild('forcastSummaryReport') childelement: ElementRef | undefined;
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.chartWidth();
  }
  fulfillmentData: ForecastDemandFullReport[] = [];
  ngAfterViewInit() {
    this.chartWidth();
  }

  private chartWidth() {
    const child = this.childelement?.nativeElement;
    if (child) {
      this.width = (Number(child.offsetWidth) - 100).toString();
    }
  }
  ngOnInit(): void {

  }

  handleFilterData(filters: any) {
    this.isLoading = true;
    this.sub.sink = this.kpiDataService.GetForecastDemandYearReport(JSON.parse(filters)).subscribe((dwellKpi: any[]) => {
      dwellKpi.sort((a, b) => {
        if (a.year !== b.year) {
          return a.year - b.year;
        }
        return a.month - b.month;
      });
      this.loadForecastDemandYearReport(dwellKpi);
      this.isForecastDemandReportByLoading$.next(false);
      this.isLoading = false;
    },
      (error) => {
        console.error('API call failed:', error);
        this.isLoading = false;
        this.isForecastDemandReportByLoading$.next(false);      
    });
  }

  constructor(private kpiDataService: KpiDataService,
    private percentPipe: PercentPipe
  ) {
  }
  loadForecastDemandYearReport(dataset: ForecastDemandFullReport[]) {
    if (dataset == null) return;
    this.fulfillmentData = dataset;
    this.setForecastDemandTableReport(dataset);
  }

  setForecastDemandTableReport(dataset: ForecastDemandFullReport[]) {
    var monthlyData = cloneDeep(this.dataSource);
    this.mapReportData(dataset);
  }

  mapReportData(dataset: ForecastDemandFullReport[]) {
    var reportDataSource = cloneDeep(this.getForecastDemandReportLineChart());
    let categories = [{ category: dataset.map(item => ({ label: item.monthName })) }];

    let releasedCarsList = dataset.map(item => ({ value: item.releasedCars }));
    let monthlyForecastList = dataset.map(item => ({ value: item.monthlyForecast }));
    let ordersMissedList = dataset.map(item => ({ value: item.ordersMissed }));

    (reportDataSource.categories as { category: { label: string }[] }[]) = categories;

    reportDataSource.dataset.forEach((line: any) => {
      if (line.seriesname == "Shipped") {
        line.data = releasedCarsList;
      }
      if (line.seriesname == "Forecasted") {
        line.data = monthlyForecastList;
      }
      if (line.seriesname == "Forecast Accuracy") {
        line.data = ordersMissedList;
      }
    });
    dataset.forEach((line: any) => {
      if (line.releasedCars > 0 || line.monthlyForecast > 0 || line.ordersMissed > 0) {
        this.noData = false;
      }
    });
    this.dataSource = reportDataSource;
  }

  getForecastDemandReportLineChart() {

    let lineChartData = {
      chart: {
        caption: "",
        yaxisname: "",
        subcaption: "",
        showhovereffect: "1",
        showlegend: "0",
        numbersuffix: "",
        drawcrossline: "1",
        drawAnchors: "0",
        labelDisplay: "rotate",
        plottooltext: "<b>$dataValue</b> of $seriesName",
        theme: "fusion"
      },
      categories: [],
      dataset: [
        {
          seriesname: "Forecasted",
          color: "#1B365D",
          dashed: "1",
          data: []
        },
        {
          seriesname: "Shipped",
          color: "#1B365D",
          data: []
        },
        {
          seriesname: "Forecast Accuracy",
          color: "#F24444",
          data: []
        },
      ]
    }
    return lineChartData;
  }
}
